






import { defineComponent, computed } from '@vue/composition-api';
import { genChart, genDataId } from '../utils';
import DataSchema from '../@types/data-schema';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object as () => DataSchema,
    },
  },
  setup(props) {
    const dataId = genDataId();
    const { dataSet, dataLabels } = props.data;

    const hasDataSet = computed(() => dataSet.length > 0);
    const hasTableHeader = computed(() => Object.keys(dataLabels).length > 0);

    const chart = genChart({
      data: {
        columns: dataSet,
        type: 'donut',
      },
      bindto: dataId,
    });
    return {
      hasDataSet,
      hasTableHeader,
      dataId,
    };
  },
});
